import React, { useEffect, useRef, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import MainHeader from '../../../common/components/MainHeader';
import SocialMediaCSS from "../SocialMediaCSS/SocialMedia.module.css";
import LeftSidebar from "./LeftSidebar/LeftSidebar";
import Post from "./Posts/Post";
import image from "../../../assets/images/Nature.svg";
import RightSidebar from "./RightSidebar/RightSidebar";
import CustomSnackbar from "../../../common/components/CommonSnackbar";
import { useSelector } from "react-redux";
import { getIndianCSRList, getIndianCSRPreview } from "../actions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import EmptyPostCard from "./Posts/PostSubComponents/EmptyPostCard";
import { resetSelectedHashtag, setSelectedSlug } from "../../../store/slices/SocialMediaSlice";

const Schedule7Layout = () => {
    const dispatch = useDispatch();
    const { socialMedia } = useSelector((state) => { return state; });
    const params = useParams();
    const snackbarProps = useSelector((state) => state.message);
    const [loading, setLoading] = useState(false)
    let offset = useRef(10);
    let totalCount = useRef(0);
    let totalPosts = useRef(0);
    let isFecthing = false;
    // const isFecthing = useRef(false);
    useEffect(() => {
        console.log('Attaching scroll event listener');
        // Add event listener when component mounts to detect scroll
        window.addEventListener('scroll', handleScroll);

        return () => {
            console.log('Removing scroll event listener');
            // Remove event listener when component unmounts
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        dispatch(resetSelectedHashtag());
    }, [])
    useEffect(() => {
        if (socialMedia?.postDashboard) {
            totalCount.current = socialMedia?.postDashboard?.length;
            totalPosts.current = socialMedia?.totalPostCount;
        }
    }, [socialMedia?.postDashboard, socialMedia?.totalPostCount])

    const handleScroll = () => {
        // Detect if user has scrolled to the bottom of the page
        console.log('handleScroll triggered');
        if (isFecthing) return;
        if (totalCount.current < totalPosts.current) {
            console.log(totalCount.current, 'running')
            if (Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.scrollHeight) {
                // Fetch more data when user scrolls to the bottom
                try {
                    isFecthing = true;
                    offset.current = offset.current + 10;
                    // if (categoryValues.current && filterValues.current) {
                    //     // dispatch(getDashboardPost(offset.current, true, setLoading, filterValues.current, categoryValues.current));
                    // }
                    // if (filterValues.current) {
                    //     // dispatch(getDashboardPost(offset.current, true, setLoading, filterValues.current));
                    // }
                    if (totalCount.current) {
                        dispatch(getIndianCSRPreview(offset.current, true, setLoading));
                    }

                } catch (error) {
                    isFecthing = false;
                }
                setTimeout(() => {
                    isFecthing = false;
                }, 2000);
            }
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        // dispatch(getIndianCSRList())
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo(0, 0);
        if (params?.slug) {
            // console.log("slug",params.slug)
           dispatch(setSelectedSlug(params.slug))
            dispatch(getIndianCSRPreview(params.slug))
        }
    }, [params?.slug]);

    return (
        <>
            <Box className={SocialMediaCSS.MainHeader}>
                <MainHeader />
            </Box>
            {Object.keys(snackbarProps)?.length > 0 && <Box textAlign='center' width='100%' ><CustomSnackbar /> </Box>}
            <Grid container className={SocialMediaCSS.bgGrey100} height="100vh" >
                <Grid item xs={12} className={SocialMediaCSS.mainContent} sx={{ overflow: 'visible' }}>
                    <Container maxWidth={'xl'}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={3} lg={3}>
                                <LeftSidebar />
                            </Grid>

                            <Grid item xs={12} md={9} lg={9}>
                                <Box className={SocialMediaCSS.social_Impact} position="relative">
                                    <img src={socialMedia?.indianCsrPreview?.image_url} className={SocialMediaCSS.thematicimage} />
                                    <Box className={SocialMediaCSS.ImageBlurImpact}>
                                        <Typography className={SocialMediaCSS.thematicTypygraphy}>
                                            {socialMedia?.indianCsrPreview?.title}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className={SocialMediaCSS.socialText}>
                                    <Box className={SocialMediaCSS.ImpactHashtag}>
                                        {socialMedia?.indianCsrPreview?.hashtags?.map((data, index) => (
                                            <Typography className={SocialMediaCSS.ImpactTypography}>{data}</Typography>
                                        ))}
                                    </Box>
                                </Box>
                                <Grid container spacing={3} pt={3}>
                                    <Grid item xs={12} md={8} lg={8}>
                                        {socialMedia?.indianCsrPreview?.posts?.length > 0 ? (
                                            <Post />
                                        ) : (
                                            <EmptyPostCard />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <RightSidebar />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </>
    );
}

export default Schedule7Layout;
