import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, IconButton, Dialog, useMediaQuery, Tooltip } from "@mui/material";
import SocialMediaCSS from "../../../SocialMediaCSS/SocialMedia.module.css";
import cancel from "../../../../../assets/icons/Cancel.svg"
import DownArrow from "../../../../../assets/icons/DownArrow.svg";
import ReduxForm from "../../../../../common/components/ReduxForm";
import CommonInput from "../../../../../common/components/CommonInput";
import tag from "../../../../../assets/icons/tag.svg";
import media from "../../../../../assets/icons/media.svg";
import activity from "../../../../../assets/icons/activity.svg";
import CommonButton2 from "../../../../../common/components/CommonButton2";
import { useDispatch } from "react-redux";
import Grateful from "../../../../../assets/icons/Grateful.svg";
import Attend from "../../../../../assets/icons/Attending.svg";
import support from "../../../../../assets/icons/support.svg";
import Celeberating from "../../../../../assets/icons/celebrating.svg";
import Participate from "../../../../../assets/icons/Participate.svg";
import global from "../../../../../assets/icons/global.svg";
import ceo from "../../../../../assets/icons/ceo 1.svg";
import ngo from "../../../../../assets/icons/non-profit-organization 2.svg";
import vlog from "../../../../../assets/icons/vlog 2.svg";
import employee from "../../../../../assets/icons/employee 1.svg";
import csr from "../../../../../assets/icons/CSR consulting.svg";
import { addUploadedFile, removeUploadedFile, setSelectedActivity, setSelectedPostAudience, setPostAudienceEdit, setPostCategoryEdit, setPostActivityEdit, setPostTagPeopleEdit, clearUploadedFiles, setDownloadCertificate, setSelectedThematic, resetSelectedThemetic } from "../../../../../store/slices/SocialMediaSlice";
import { useDropzone } from 'react-dropzone';
import deleteIcon from "../../../../../assets/icons/DeleteWhite.svg"
import { setSnackbar } from "../../../../../store/slices/MessageSlice";
import { reset } from "redux-form";
import { useSelector } from "react-redux";
import CommonInitials from "../../../../../common/components/CommonInitials";
import theme from "../../../../../assets/material-ui/theme";
import { capitalizeFirstLetter, getThematicNameById, } from "../../../../../common/utils";
import del from "../../../../../assets/icons/deleteRed.svg";
import { deletePostImages, editPost, shareCertificatePost } from "../../../actions";
import demo_certificate from '../../../../../assets/images/demo_certificate.png';
import ThematicIcon from '../../../../../assets/icons/Thematic.svg';
import share from "../../../../../assets/icons/shareWhite.svg";
import { useNavigate } from "react-router";
import { getMentionsList } from "../../../../../common/actions";

const CreatePost = ({ socialMedia, usersImg, form, selectedActivity, setSelectedPostTitle, setSelectedActivity1, selectedTag, selectedPostTitle,unSelectedTag, master, opens, onClose, Title, post, isEvent, eventGid, eventThematic }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const uploadedFiles = useSelector((state) => state.socialMedia.uploadedFiles);
    const certificate = useSelector((state) => state.socialMedia.downloadCertificate)
    const [postImages, setPostImages] = useState(post?.primary_post?.post_images || []);
    const [textExceeded, setTextExceeded] = useState(false);
    const isArticle = socialMedia.articleCreation;
    const ActivityPost = socialMedia.selectedActivity;
    const postTypeKey = post?.primary_post?.post_type_name;
    const postType = master?.postTypes?.find(type => type?.key === postTypeKey);
    const postTypeValue = postType?.value;
    // const initialValues = {
    //     post_description: post?.body?.replace(/^"|"$/g, ''),
    // };
    const initialValues = {
        post_description: post?.body
            ? post.body
                .replace(/^"|"$/g, '')
                .replace(/\r\n/g, '\n')
                .replace(/\\n/g, '\n') 
                .trim()
            : ''
    };

    useEffect(() => {
        dispatch(getMentionsList());
    }, [dispatch]);

    // console.log("console", post.gid)
    // console.log(post?.shareable, "sell")
    const handleExceedLimit = (exceeded) => {
        if (exceeded) {
            setTextExceeded(true)
        } else {
            setTextExceeded(false)
        }
    };
    const handleCreateCancel = () => {
        dispatch(reset('CreatePostform'));
        setSelectedActivity1(capitalizeFirstLetter(post?.primary_post?.activity?.key));
        setSelectedPostTitle(postTypeValue);
        dispatch(setSelectedActivity(""));
        dispatch(setSelectedThematic([]));
        dispatch(clearUploadedFiles());
        dispatch(setDownloadCertificate(''));
        dispatch(resetSelectedThemetic());
        onClose();
    };
    const handleActivityClick = () => {
        dispatch(setPostActivityEdit(true))
    };
    const handleTagClick = () => {
        dispatch(setPostTagPeopleEdit(true))

    };
    const handlePostAudience = () => {
        dispatch(setPostAudienceEdit(true))
    }
    const handleNextClick = () => {
        dispatch(setSelectedActivity(selectedActivity));
        dispatch(setSelectedPostAudience(selectedPostTitle));
        if (isEvent) {
            dispatch(setSelectedThematic([eventThematic]));
            dispatch(shareCertificatePost(eventGid, onClose, navigate));
        } else {
            dispatch(setPostCategoryEdit(true))
        }
    }
    const handlePostClick = (gid) => {
        dispatch(editPost(gid, onClose));
    }
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleDlgClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }
        onClose();
    }
    const handleDrop = (acceptedFiles) => {
        const isVideo = acceptedFiles.some(file => file?.type?.startsWith('video'));
        const isImage = acceptedFiles.some(file => file?.type?.startsWith('image'));
        const totalUploadedFiles = post?.primary_post?.post_images?.length + uploadedFiles.length;

        if (totalUploadedFiles + acceptedFiles.length > 5) {
            dispatch(setSnackbar({ flag: true, message: 'You can upload a maximum of 5 images or videos.', type: 'error' }));
            return;
        }
        if (post?.primary_post?.post_images?.length > 0 && isVideo) {
            dispatch(setSnackbar({ flag: true, message: 'You cannot upload a video when images are already uploaded.', type: 'error' }));
            return;
        }
        if (post?.primary_post?.post_video?.length > 0 && isImage) {
            dispatch(setSnackbar({ flag: true, message: 'You cannot upload an image when videos are already uploaded.', type: 'error' }));
            return;
        }

        if (isVideo && isImage) {
            dispatch(setSnackbar({ flag: true, message: 'You can upload either images or a video, not both.', type: 'error' }));
            return;
        }

        if (isVideo && uploadedFiles.some(file => file?.type?.startsWith('image'))) {
            dispatch(setSnackbar({ flag: true, message: 'You cannot upload a video because images have already been uploaded.', type: 'error' }));
            return;
        }

        if (isImage && uploadedFiles.some(file => file?.type?.startsWith('video'))) {
            dispatch(setSnackbar({ flag: true, message: 'You cannot upload images because a video has already been uploaded.', type: 'error' }));
            return;
        }

        if (isVideo && acceptedFiles.length > 1) {
            dispatch(setSnackbar({ flag: true, message: 'You can upload only one video.', type: 'error' }));
            return;
        }

        if (isImage && (acceptedFiles.length + uploadedFiles.length > 5)) {
            dispatch(setSnackbar({ flag: true, message: 'You can upload a maximum of 5 images.', type: 'error' }));
            return;
        }
        for (let file of acceptedFiles) {
            if (file.type.startsWith('image/') && file.size > 2 * 1024 * 1024) { // 2 MB in bytes
                dispatch(setSnackbar({ flag: true, message: 'File size cannot exceed 2 MB.', type: 'error' }));
                return;
            }
            if (file.type.startsWith('video') && file.size > 50 * 1024 * 1024) { // 25 MB in bytes
                dispatch(setSnackbar({ flag: true, message: 'Video file size cannot exceed 50 MB.', type: 'error' }));
                return;
            }
        }
        // setUploadedFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
        // console.log("Uploaded files:", [...uploadedFiles, ...acceptedFiles]);
        acceptedFiles.forEach(file => {
            dispatch(addUploadedFile(file));
        });

    };

    const handleRemoveFile = (index) => {
        dispatch(removeUploadedFile(index));
        // setUploadedFiles(prevFiles => prevFiles.filter((file, i) => i !== index));
    };
    const handleUploadedRemoveFile = (postId, gid) => {
        // console.log(`Removing image with GID: ${gid} from post with GID: ${postId}`);
        dispatch(deletePostImages(postId, gid));
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: handleDrop,
        accept: { 'image/*': ['.jpeg', '.jpg', '.png'], 'video/*': ['.mp4'] },
        noClick: true,
        noKeyboard: true
    });

    const activityImages = { "Supporting": support, "Attending": Attend, "Grateful": Grateful, "Celebrating": Celeberating, "Participating": Participate };
    const PostImages = { "Public": global, "Govt. Agency": employee, "Non Profit | NGO": ngo, "Corporate/Company": ceo, "CSR | ESG Expert": csr, "Employees | Volunteer | Individual": vlog, };

    const selectedImage = activityImages[selectedActivity];
    // const selectedPostImage = PostImages[selectedPostTitle];
    const selectedAudienceObject = master?.postTypes?.find(audience => audience?.value === selectedPostTitle);
    const selectedAudienceImage = selectedAudienceObject ? selectedAudienceObject.image : null;
    const tags = master.hashtags.hashtags;
    const users = master.mentions;
    const isVideoUploaded = uploadedFiles.some((file) => file?.type?.startsWith('video'));
    const uploadedPostImages = post?.primary_post?.post_images?.length
    const uploadedPostVideo = post?.primary_post?.post_video?.length
    const isuploadCount = uploadedPostImages + uploadedFiles.length
    const postReshare =
        !post?.shared_module?.event_gid &&
        !post?.shared_module?.conferenec_gid &&
        !post?.shared_module?.rfp_gid;
    // console.log("Post GID:", post?.gid, "PostReshare:", postReshare);
    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={opens}
                onClose={handleDlgClose}
                aria-labelledby="Edit Post"
                PaperProps={{ classes: { root: SocialMediaCSS.shareToPopup } }}
            >
                <Box className={SocialMediaCSS.postCard}>
                    <Box className={SocialMediaCSS.postHeader}>
                        <Box className={SocialMediaCSS.postHeaderLogo}>
                            {/* <img src='https://picsum.photos/id/684/600' alt='Name of Post Owner' /> */}
                            <CommonInitials
                                name={usersImg.userData.initials}
                                bgColor={usersImg.userData.color_code}
                                divStyle={{ padding: '0px', maxHeight: '135px', maxWidth: '132px', minHeight: '46px', minWidth: '46px', borderRadius: '50%', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)", cursor: 'pointer' }}
                                typoStyle={{ fontSize: '18px', lineHeight: '46px', }}
                                avatarStyle={{ width: '46px', height: '46px', boxShadow: "0px 0px 25px 0px rgba(170, 170, 170, 0.25)" }}
                                src={usersImg?.userData?.profile_pic}
                                companyLogo={usersImg?.userData?.profile_pic}
                                avatar={true}
                            />
                        </Box>
                        <Box className={SocialMediaCSS.postHeaderInfo}>
                            <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`}>
                                <Typography className={SocialMediaCSS.organisaion}>
                                    {usersImg?.userData?.name}
                                </Typography>&nbsp;
                                {selectedActivity &&
                                    <Box className={`${SocialMediaCSS.f} ${SocialMediaCSS.ai_center}`} sx={{ gap: '5px' }}>
                                        <Typography className={SocialMediaCSS.organisaion}> -</Typography>
                                        <img src={selectedImage} className={SocialMediaCSS.createImgActivity} />
                                        <Typography className={SocialMediaCSS.organisaion}>{selectedActivity}</Typography>
                                    </Box>}
                            </Box>
                            <Box className={`${SocialMediaCSS.createBox}`} onClick={handlePostAudience}>
                                {selectedPostTitle ? (
                                    <img src={selectedAudienceImage} className={SocialMediaCSS.headAudi} />
                                ) : (
                                    <img src={global} className={SocialMediaCSS.headAudi} />
                                )}
                                <Typography variant='body4' className={SocialMediaCSS.postHeaderTagline}>
                                    {selectedPostTitle}
                                </Typography>
                                <img src={DownArrow} />
                            </Box>
                        </Box>
                        <Box className={SocialMediaCSS.cancelIcon}
                            onClick={() => handleCreateCancel()}
                        >
                            <img src={cancel} className={SocialMediaCSS.createPost} />
                        </Box>
                    </Box>
                    <ReduxForm
                        form="CreatePostform"
                        label="CreatePost"
                        initialValues={initialValues}
                        submitButton={isEvent ? "Post" : "Next"}>
                        <Grid container>
                            <Grid item xs={12} mt={-1.2}>
                                <CommonInput
                                    name="post_description"
                                    placeholder="Write your post"
                                    type="socialInput"
                                    users={users}
                                    tags={tags}
                                    maxLength={"5000"}
                                    showCount={true}
                                    countFontSize="12px"
                                    rows="6"
                                    selectedTags={selectedTag}
                                    unSelectedTag={unSelectedTag}
                                    onExceedLimit={handleExceedLimit}
                                />
                            </Grid>

                            <Box className={`${SocialMediaCSS.filePreview} ${SocialMediaCSS.f}`} gap={1}>
                                {/* {post?.primary_post?.post_images && (
                                    <>
                                        {post?.primary_post?.post_images?.map((image, index) => (
                                            <Box key={index}>
                                                <img src={image?.images_url} alt={`image-${image.gid}`} className={SocialMediaCSS.imagePreview} />
                                                <IconButton className={SocialMediaCSS.removeButton} onClick={() => handleUploadedRemoveFile(image.gid, post.gid)}>
                                                    <img src={deleteIcon} alt="Delete" />
                                                </IconButton>
                                            </Box>
                                        ))}
                                    </>
                                )} */}
                                {post?.primary_post?.post_images && (
                                    <div gap={1}>
                                        {post.primary_post.post_images.map((image, index) => (
                                            <Box key={image.gid} className={SocialMediaCSS.filePreview} mr={1.2}>
                                                <img src={image.images_url} alt={`image-${image.gid}`} className={SocialMediaCSS.imagePreview} />
                                                <IconButton onClick={() => handleUploadedRemoveFile(image.gid, post?.gid)} className={SocialMediaCSS.removeButton}>
                                                    <img src={deleteIcon} alt="Delete" />
                                                </IconButton>
                                            </Box>
                                        ))}
                                    </div>
                                )}
                                {post?.primary_post?.post_video && (
                                    <>
                                        {post?.primary_post?.post_video?.map((video, index) => (
                                            <Box key={index}>
                                                <video controls className={SocialMediaCSS.previewVideo}>
                                                    <source src={video?.video_url} />
                                                </video>
                                                <IconButton onClick={() => handleUploadedRemoveFile(video.gid, post.gid)} className={SocialMediaCSS.removeButton}>
                                                    <img src={deleteIcon} />
                                                </IconButton>
                                            </Box>
                                        ))}
                                    </>
                                )}
                            </Box>
                            <Box className={SocialMediaCSS.previewBox}>
                                {uploadedFiles?.map((file, index) => (
                                    <Box key={index} className={SocialMediaCSS.filePreview}>
                                        {file?.type?.startsWith('image') && (
                                            <>
                                                <img src={URL?.createObjectURL(file)} alt={`img-${index}`} className={SocialMediaCSS.imagePreview} />
                                                <IconButton onClick={() => handleRemoveFile(index)} className={SocialMediaCSS.removeButton}>
                                                    <img src={deleteIcon} />
                                                </IconButton>
                                            </>
                                        )}
                                        {file?.type?.startsWith('video') && (
                                            <>
                                                <video controls className={SocialMediaCSS.previewVideo}>
                                                    <source src={URL.createObjectURL(file)} type={file.type} />
                                                </video>
                                                <IconButton onClick={() => handleRemoveFile(index)} className={SocialMediaCSS.removeButton}>
                                                    <img src={deleteIcon} />
                                                </IconButton>
                                            </>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                            {isEvent &&
                                <>
                                    <Box className={SocialMediaCSS.shareCertificateBox}>
                                        <img src={certificate} alt="certificate" className={SocialMediaCSS.shareCertificateImg} loading="lazy" />
                                    </Box>
                                    <Box className={SocialMediaCSS.shareCertificateThematicBox}>
                                        <img src={ThematicIcon} className={SocialMediaCSS.h16} />
                                        <Typography variant='body4' className={SocialMediaCSS.shareCertificateThematicTypo}>
                                            {dispatch(getThematicNameById(eventThematic))}
                                        </Typography>
                                    </Box>
                                </>
                            }
                            <Grid item xs={12} className={SocialMediaCSS.createGrid}>
                                {(post?.shareable === false || (post?.shareable === true && postReshare)) && (
                                    <Box className={SocialMediaCSS.createOption}>
                                        {/* <img src={media} className={uploadedFiles.length >= 5 || isVideoUploaded ? SocialMediaCSS.unclickable : SocialMediaCSS.Imagecursor} onClick={open} /> */}
                                        {(post?.shareable === false || (post?.shareable === true && !postReshare)) && !isEvent && (
                                            < Tooltip title="Image/Video" arrow placement="top"><img src={media} className={isuploadCount >= 5 || isVideoUploaded || uploadedPostVideo > 0 ? SocialMediaCSS.unclickable : SocialMediaCSS.Imagecursor} onClick={open} /></Tooltip>
                                        )}
                                        {/* {!isEvent && <Tooltip title="Image/Video" arrow placement="top"><img src={media} className={isuploadCount >= 5 || isVideoUploaded || uploadedPostVideo > 0 ? SocialMediaCSS.unclickable : SocialMediaCSS.Imagecursor} onClick={open} /></Tooltip>} */}
                                        <Tooltip title="Tag" arrow placement="top"> <img src={tag} className={SocialMediaCSS.cursor} onClick={handleTagClick} style={{ verticalAlign: 'middle' }} /></Tooltip>
                                        <Tooltip title="Activity" arrow placement="top" ><img src={activity} className={SocialMediaCSS.cursor} onClick={handleActivityClick} /></Tooltip>
                                    </Box>)}
                                {isEvent &&
                                    < CommonButton2
                                        label="| Post"
                                        name="Post"
                                        variant="contained"
                                        startIcon={<img src={share} />}
                                        type="button"
                                        onClick={() => handleNextClick()}
                                        disabled={textExceeded || (!form?.CreatePostform?.values || !form?.CreatePostform?.values?.post_description)}
                                        style={{
                                            padding: '6px 16px', fontSize: '14px', height: '32px', fontWeight: '600', boxShadow: 'none',
                                            background: 'var(--Button-BG-Blue, linear-gradient(180deg, #0A97D9 0%, #00689D 100%', color: '#FFF',
                                            opacity: !form?.CreatePostform?.values || !form?.CreatePostform?.values?.post_description ? 0.5 : 1
                                        }}
                                    />}
                                {/* {(post?.shareable === false || (post?.shareable === true && post?.primary_post?.shared_module?.event_gid === null && post?.primary_post?.shared_module?.conferenec_gid === null && post?.primary_post?.shared_module?.rfp_gid === null )) &&
                                    <Box className={SocialMediaCSS.nextPost}>
                                        <CommonButton2
                                            label="Next"
                                            name="Next"
                                            variant="outlined"
                                            type="button"
                                            onClick={() => handleNextClick()}
                                            style={{ padding: '6px 32px', fontSize: '14px', height: '32px', fontWeight: '600', boxShadow: 'none' }}
                                            disabled={textExceeded || (!form?.CreatePostform?.values || !form?.CreatePostform?.values?.post_description)}
                                        />
                                    </Box>
                                } */}
                                {(post?.shareable === false || (post?.shareable === true && postReshare)) ? (
                                    <Box className={SocialMediaCSS.nextPost}>
                                        <CommonButton2
                                            label="Next"
                                            name="Next"
                                            variant="outlined"
                                            type="button"
                                            onClick={() => handleNextClick()}
                                            style={{ padding: '6px 32px', fontSize: '14px', height: '32px', fontWeight: '600', boxShadow: 'none' }}
                                            disabled={textExceeded || (!form?.CreatePostform?.values?.post_description)}
                                        />
                                    </Box>
                                ) : (
                                    <Box sx={{ marginLeft: "auto" }}>
                                        <CommonButton2
                                            label="| Update"
                                            name="Update"
                                            variant="contained"
                                            startIcon={<img src={share} />}
                                            type="button"
                                            onClick={() => handlePostClick(post.gid)}
                                            disabled={!form?.CreatePostform?.values?.post_description}
                                            style={{
                                                padding: '6px 16px', fontSize: '14px', height: '32px', fontWeight: '600', boxShadow: 'none',
                                                background: 'var(--Button-BG-Blue, linear-gradient(180deg, #0A97D9 0%, #00689D 100%)',
                                                color: '#FFF',
                                                opacity: !form?.CreatePostform?.values?.post_description ? 0.5 : 1
                                            }}
                                        />
                                    </Box>
                                )}


                                {/* {post?.shareable === true &&
                                    <Box sx={{ marginLeft: "auto" }}>
                                        < CommonButton2
                                            label="| Update"
                                            name="Update"
                                            variant="contained"
                                            startIcon={<img src={share} />}
                                            type="button"
                                            onClick={() => handlePostClick(post.gid)}
                                            disabled={!form?.CreatePostform?.values || !form?.CreatePostform?.values?.post_description}
                                            style={{
                                                padding: '6px 16px', fontSize: '14px', height: '32px', fontWeight: '600', boxShadow: 'none',
                                                background: 'var(--Button-BG-Blue, linear-gradient(180deg, #0A97D9 0%, #00689D 100%', color: '#FFF',
                                                opacity: !form?.CreatePostform?.values || !form?.CreatePostform?.values?.post_description ? 0.5 : 1
                                            }}
                                        />
                                    </Box>
                                } */}
                            </Grid>
                        </Grid>
                        <Box {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                        </Box>
                    </ReduxForm>
                </Box>
            </Dialog >
        </>
    )
}

export default CreatePost