import { Box, IconButton, Popover, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import UserProfileImg from '../../../../assets/images/projectTempImage.jpg';
import PostTime from '../../../../assets/icons/clock_Solid.svg';
import { convertHashTagToLink, convertMentionsToLinks, convertUrlsToLinks, getCompanyTypeByName, isJSON, timeAgo } from '../../../../common/utils';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CommentIcon from '../../../../assets/icons/comment_lightGrey.svg';
import LikeIcon from '../../../../assets/icons/like_lightGrey.svg';
import DeleteIcon from '../../../../assets/icons/delete_lightGrey.svg';
import AllReactions from './AllReactions';
import EditIcon from '../../../../assets/icons/edit_lightGrey.svg';
import CommentInputContainer from './CommentInputContainer';
import { deletePostReply, getHashtagData } from '../../actions';
import FlagIcon from '../../../../assets/icons/flag_lightGrey.svg';
import ReportDrawer from '../Drawer/ReportDrawer';
import CommonInitials from '../../../../common/components/CommonInitials';
import { ConnnectionList } from '../Popup/ConnectionList';
import { setPostMode } from '../../../../store/slices/SocialMediaSlice';
import { useNavigate } from 'react-router';

const ReplyRow = ({ SocialMediaCSS, socialMedia, data, master, gid, dispatch, users, commentsGid }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [popupProps, setPopupProps] = useState({});
    const open = Boolean(anchorEl);
    const commentMorePopover = open ? 'simple-popover' : undefined;
    const [reportDraweropen, setReportDraweropen] = useState(false);
    const [connectionDialogOpen, setConnectionDialogOpen] = useState(false);

    const handleProfileDialogOpen = (props) => {
        setPopupProps(props);
        setConnectionDialogOpen(true);
        dispatch(setPostMode('CommentReply'));
    };

    const handleProfileDialogClose = () => {
        setConnectionDialogOpen(false);
    };
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const handleEdit = (id) => {
        setIsEditing(true);
        handlePopoverClose();
    }
    const handleDelete = (id) => {
        dispatch(deletePostReply(id, gid));
        handlePopoverClose();
    }
    const handleReportDrawerOpen = () => {
        setReportDraweropen(true);
        handlePopoverClose();
    };

    const handleReportDrawerClose = () => {
        setReportDraweropen(false);
    };
    const user_type = dispatch(getCompanyTypeByName(data?.profile_type));
    const reactionIds = data?.all_reactions?.map(reaction => reaction?.key);
    const uniqueReactions = new Set(reactionIds);
    //console.log("blue", data)
    const handleUserProfile = (gid) => {
        console.log("gid", gid)
        navigate(`/profile/${gid}`);
    }
    //console.log(`Postl: `, data);
    const commentBody = data?.body && convertUrlsToLinks(convertHashTagToLink(convertMentionsToLinks(isJSON(data?.body) ? JSON.parse((data?.body)) : data?.body, SocialMediaCSS.contentHashtag), SocialMediaCSS.contentHashtag));
    const handleHashtagClick = (event, hashtag) => {
        event.preventDefault();
        event.stopPropagation();
        if (users?.isLoggedIn) {
            dispatch(getHashtagData(hashtag));
            navigate(`/social-media/hashtags/${hashtag}`);
        } else {
            navigate('/login');
        }
    };

    useEffect(() => {
        window.handleHashtagClick = handleHashtagClick;
        return () => {
            delete window.handleHashtagClick;
        };
    }, []);
    return (
        <>
            <Box className={SocialMediaCSS.commentRow}>
                <Box className={SocialMediaCSS.cursor} onClick={() => handleUserProfile(data?.user_gid)}>
                    <CommonInitials name={data?.profile_initials} bgColor={data?.profile_colour_code} divStyle={{ padding: '3px', maxHeight: '36px', maxWidth: '36px', minHeight: '36px', minWidth: '36px', borderRadius: '50%', flex: '0 0 36px', borderColor: '#e9ecef', border: '1px solid #dee2e6' }} typoStyle={{ fontSize: '14px', lineHeight: '30px', }} avatarStyle={{ width: '36px', height: '36px', }} src={data?.profile_logo} companyLogo={data?.profile_logo} avatar={true} />
                </Box>
                <Box className={SocialMediaCSS.commentAllData}>
                    <Box className={SocialMediaCSS.primaryCommentData}>
                        <Box className={SocialMediaCSS.commentHeader}>
                            <Box className={SocialMediaCSS.userInfo}>
                                <Typography variant='body2' className={`${SocialMediaCSS.userName} ${SocialMediaCSS.cursor}`} onClick={() => handleUserProfile(data?.user_gid)}>
                                    {data?.name}
                                </Typography>
                                <Typography variant='body4' className={SocialMediaCSS.taglineReply}>
                                    {/* {data?.user_personal_type} */}
                                    {data?.tagline}
                                </Typography>
                            </Box>
                            <Box className={SocialMediaCSS.commentInfo}>
                                <Box className={SocialMediaCSS.postHeaderTime}>
                                    <img src={PostTime} alt='Post Time' />
                                    <Typography variant='body4' className={`${SocialMediaCSS.otherText} ${SocialMediaCSS.noBreak}`} >
                                        {timeAgo(data?.updated_at)}
                                    </Typography>
                                </Box>
                                <IconButton
                                    aria-describedby={commentMorePopover}
                                    onClick={handlePopoverOpen}
                                    className={SocialMediaCSS.commentMore}
                                >
                                    <MoreHorizIcon className={SocialMediaCSS.h18} />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box className={SocialMediaCSS.commentBody}>
                            <Box className={SocialMediaCSS.commentTriangle}></Box>
                            <Box className={SocialMediaCSS.commActContainer}>
                                {isEditing ? <CommentInputContainer gid={data?.gid} postId={gid} comment={false} show={false} value={data?.body} editFlag={true} setIsEditing={setIsEditing} users={users} master={master} /> : <Box className={SocialMediaCSS.commentTextContainer}>
                                    <Typography variant='body2' className={SocialMediaCSS.commentText}>
                                        {/* {data?.body} */}
                                        <p dangerouslySetInnerHTML={{ __html: commentBody }} />
                                    </Typography>
                                </Box>}
                                <Box className={SocialMediaCSS.commentActions}>
                                    <Box className={SocialMediaCSS.commentReactionCountBox}>
                                        <Box className={SocialMediaCSS.actionStatsCount}
                                            onClick={reactionIds?.length !== 0 ? () => handleProfileDialogOpen({ title: "Reaction", reaction: "true", type: "PostComment", commentId: commentsGid, replyId: data.gid }) : null}
                                        >
                                            {[...uniqueReactions].map((reactionKey) => {
                                                const reaction = master?.postReaction?.find(react => react?.key === reactionKey);
                                                const selectedReactionImage = reaction ? reaction.image : null;
                                                // console.log("Reaction Key:", reactionKey); 
                                                // console.log("Reaction Object:", reaction);
                                                // console.log("Selected Reaction Image:", selectedReactionImage);
                                                return (
                                                    <Box className={SocialMediaCSS.postReacted}>
                                                        <img src={selectedReactionImage} className={SocialMediaCSS.h12} alt='reaction' />
                                                    </Box>)
                                            })}
                                            {reactionIds?.length === 0 || reactionIds?.length === undefined &&
                                                <img src={LikeIcon} className={SocialMediaCSS.h12} alt='Like' />
                                            }
                                            {/* <img src={LikeIcon} className={SocialMediaCSS.h12} alt='Like' /> */}
                                            <Typography variant='body4' className={SocialMediaCSS.statsText} ml={0.6}>
                                                {data?.reaction_count}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className={SocialMediaCSS.commentReactions}>
                                        <AllReactions master={master} postId={data?.id} dispatch={dispatch} type={'CommentReply'} parentComment={commentsGid} commentReplyId={data?.personal_reactions?.[0]?.id} replyId={data.gid} replyData={data} isLoggedIn={users?.isLoggedIn} />
                                        {/* <Box className={SocialMediaCSS.actionStatsCount} onClick={() => { handleToggleReplies(data?.id) }}>
                                        <img src={CommentIcon} alt='Comment' className={SocialMediaCSS.h16} />
                                        <Typography variant='body4' className={SocialMediaCSS.statsText}>
                                            Reply
                                        </Typography>
                                    </Box> */}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Popover
                id={commentMorePopover}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className={SocialMediaCSS.popoverCommentMore}
            >
                <Box className={SocialMediaCSS.commentMoreOptionBox}>
                    {data?.editable_comment &&
                        <Box className={SocialMediaCSS.commentMoreOptionItem} onClick={() => handleEdit(data?.id)}>
                            <img src={EditIcon} alt='Delete' className={SocialMediaCSS.h14} />
                            <Typography variant='body2' className={SocialMediaCSS.commentMoreoptionName} >
                                Edit
                            </Typography>
                        </Box>}
                    {data?.deletable_comment &&
                        <Box className={SocialMediaCSS.commentMoreOptionItem} onClick={() => handleDelete(data?.gid)}>
                            <img src={DeleteIcon} alt='Delete' className={SocialMediaCSS.h14} />
                            <Typography variant='body2' className={SocialMediaCSS.commentMoreoptionName} >
                                Delete
                            </Typography>
                        </Box>}
                    {!data?.editable_comment &&
                        <Box className={SocialMediaCSS.commentMoreOptionItem} onClick={handleReportDrawerOpen}>
                            <img src={FlagIcon} alt='Delete' className={SocialMediaCSS.h14} />
                            <Typography variant='body2' className={SocialMediaCSS.commentMoreoptionName} >
                                Report
                            </Typography>
                        </Box>}
                </Box>
            </Popover>
            {connectionDialogOpen && <ConnnectionList
                open={connectionDialogOpen}
                onClose={handleProfileDialogClose}
                // Title="Reaction"
                // reaction="true"
                Title={popupProps.title}
                reaction={popupProps.reaction}
                share={popupProps.share}
                type={popupProps.type}
                socialMedia={socialMedia}
                gid={gid}
                master={master}
                commentsGid={popupProps.commentsGid}
                replyId={popupProps.replyId}
            />}
            <ReportDrawer open={reportDraweropen} onClose={handleReportDrawerClose} title="Report Comment" desc="Report an abusive or inappropriate comment." master={master} type={'CommentReply'} gid={data?.gid} />
        </>
    )
}

export default ReplyRow