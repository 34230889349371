export function flattenError(error) {
  const outErr = {};
  const outErrs = [];

  if (error) {
    flattenErrorRec(outErrs, error, '');
  }

  outErrs.forEach(err => {
    [, outErr[err[0]]] = err;
  });

  return outErr;
}

const flattenErrorRec = (outErrs, error, prefix) => {
  if (error === undefined || error === null) {
    return;
  }
  Object.keys(error).forEach(name => {
    const value = error[name];
    if (typeof value === 'string') {
      outErrs.push([prefix + name, value]);
    } else if (value instanceof Array) {
      for (let i = 0, iLen = value.length; i < iLen; i += 1) {
        flattenErrorRec(outErrs, value[i], `${prefix + name}[${i}].`);
      }
    } else {
      flattenErrorRec(outErrs, value, `${prefix + name}.`);
    }
  });
};

export function getHeaderForAPICall(headerToMerge, isBlob) {
  let defaultHeader;
  if (!isBlob) {
    defaultHeader = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }
  let headersToMerge = {};
  if (typeof headerToMerge === 'object') headersToMerge = headerToMerge;
  return Object.assign({}, defaultHeader, headersToMerge);
}

export function convertJsonToQS(dataToPost) {
  return Object.keys(dataToPost)
    .map(key => `${key}=${dataToPost[key]}`)
    .join('&');
}


export function fileToBase64(file, callback) {
  const reader = new FileReader();
  reader.onload = () => {
    const base64String = reader.result;
    callback(base64String);
  };
  reader.readAsDataURL(file);
}

export function base64ToFile(base64String, fileName, NameFileExtention) {
  const base64Parts = base64String.split(",");

  const metadata = base64Parts[0].match(/:(.*?);/);
  let fileType;

  if (metadata && metadata.length >= 2) {
    fileType = metadata[1];
    // const fileNameMatch = metadata[0].match(/(?!.*\/).*$/);
    // if (fileNameMatch && fileNameMatch.length >= 1) {
    //   fileName = fileNameMatch[0];
    // }
  }

  const byteCharacters = atob(base64Parts[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: fileType });
  let fileExtension = '';
  if (fileType) {
    const mimeTypeParts = fileType.split('/');
    if (mimeTypeParts.length === 2) {
      fileExtension = mimeTypeParts[1];
    }
  }

  let finalFileName = fileName;
  if (NameFileExtention && fileExtension) {
    if (fileExtension === "vnd.openxmlformats-officedocument.wordprocessingml.document") {
      finalFileName = `${fileName}.docx`;
    } else {
      finalFileName = `${fileName}.${fileExtension}`;
    }
  }

  const file = new File([blob], finalFileName, { type: fileType });

  return file;
}

export function base64ToFile2(base64String, fileName, NameFileExtention) {
  const base64Parts = base64String.split(",");

  const metadata = base64Parts[0].match(/:(.*?);/);
  let fileType;
  let originalFileName = '';

  if (metadata && metadata.length >= 2) {
    fileType = metadata[1];

    // Attempt to extract the file name from the metadata (if available)
    const fileNameMatch = metadata[0].match(/filename\*=UTF-8''([^;]*)/);
    if (fileNameMatch && fileNameMatch.length >= 2) {
      originalFileName = decodeURIComponent(fileNameMatch[1]);
    }
  }

  const byteCharacters = atob(base64Parts[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: fileType });
  let fileExtension = '';
  if (fileType) {
    const mimeTypeParts = fileType.split('/');
    if (mimeTypeParts.length === 2) {
      fileExtension = mimeTypeParts[1];
    }
  }
  console.log(originalFileName, fileName, `file_${Math.random().toString(36).substring(2, 15)}`, "fileName")
  let finalFileName = originalFileName || fileName || `file_${Math.random().toString(36).substring(2, 15)}`;

  if (NameFileExtention && fileExtension) {
    if (fileExtension === "vnd.openxmlformats-officedocument.wordprocessingml.document") {
      finalFileName += `.docx`;
    } else if (!finalFileName.endsWith(`.${fileExtension}`)) {
      finalFileName += `.${fileExtension}`;
    }
  }

  const file = new File([blob], finalFileName, { type: fileType });

  return file;
}


export function bytesToKb(bytes) {
  return bytes / 1024;
}

export function getFileSizeInKBFromBase64(base64String) {
  // Remove data URI prefix
  const base64WithoutPrefix = base64String.split(',')[1];
  if (base64WithoutPrefix) {
    // Decode base64 string into binary data
    const binaryData = window.atob(base64WithoutPrefix);
    // Calculate file size in kilobytes
    const fileSizeInKB = binaryData.length / 1024;
    return fileSizeInKB;
  }
}
export const isURL = (str) => {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
}
export const isDataURL = (str) => {
  if (str) {
    return str.startsWith('data:image/') || str.startsWith('data:application/') && str.includes(';base64,');
  }
}
export const removeLastSegment = (url) => {
  if (url) {
    const lastSlashIndex = url.lastIndexOf('/');
    if (lastSlashIndex !== -1) {
      return url.substring(0, lastSlashIndex);
    }
    return url; // return original url if no slash is found
  }
}
export const endsWithFile = (url) => {
  if (url) {
    const regex = /\/([^\/]+\.[a-zA-Z0-9]+)(\?.*)?$/;
    return regex.test(url);
  }
}
export function initializeMap(mapRef, geocoder) {
  if (!window.google) {
    const googleMapScript = document.createElement('script');
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCyL20r6Lze5OdTNTJ_gAcfkul6iF6wBi4&libraries=places`;
    window.document.body.appendChild(googleMapScript);

    googleMapScript.addEventListener('load', () => {
      const mapOptions = {
        center: { lat: 0, lng: 0 },
        zoom: 1,
      };
      const map = new window.google.maps.Map(mapRef.current, mapOptions);
      geocoder.current = new window.google.maps.Geocoder();
    });
  } else {
    const mapOptions = {
      center: { lat: 0, lng: 0 },
      zoom: 1,
    };
    const map = new window.google.maps.Map(mapRef.current, mapOptions);
    geocoder.current = new window.google.maps.Geocoder();
  }
}

export function handleEventAddressUpdate(eventAddress, geocoder, mapRef, setMarker) {
  if (eventAddress && geocoder.current) {
    geocoder.current.geocode({ address: eventAddress }, (results, status) => {
      if (status === "OK" && results.length > 0) {
        const { lat, lng } = results[0].geometry.location;
        const map = new window.google.maps.Map(mapRef.current);
        map.setCenter({ lat: lat(), lng: lng() });
        map.setZoom(15);
        const marker = new window.google.maps.Marker({
          position: { lat: lat(), lng: lng() },
          map: map
        });
        setMarker(marker);
      }
    });
  } else {
    setMarker(null);
  }
}
export function loadGoogleMapsScript(mapRef, geocoder, eventAddress, setMarker) {
  const initializeMap = () => {
    const mapOptions = {
      center: { lat: 0, lng: 0 },
      zoom: 1,
    };
    const map = new window.google.maps.Map(mapRef.current, mapOptions);
    geocoder.current = new window.google.maps.Geocoder();
  };

  const handleScriptLoad = () => {
    if (mapRef.current) {
      initializeMap();
    }
  };

  if (window.google) {
    handleScriptLoad();
  } else {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCyL20r6Lze5OdTNTJ_gAcfkul6iF6wBi4&libraries=places`;
    script.async = true;
    script.defer = true;
    script.addEventListener("load", handleScriptLoad);
    document.head.appendChild(script);

    return () => {
      script.removeEventListener("load", handleScriptLoad);
      document.head.removeChild(script);
    };
  }
}

export function isEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}
const dayjs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export function getFormattedDateTime(dateString) {
  const date = dayjs(dateString, { format: "YYYY-MM-DD HH:mm:ss" });
  const day = date.format("D");
  const month = date.format("MMM");
  const year = date.format("YYYY");
  const time = date.format("h:mm A");

  const daySuffix =
    day === "1" || day === "21" || day === "31"
      ? "st"
      : day === "2" || day === "22"
        ? "nd"
        : day === "3" || day === "23"
          ? "rd"
          : "th";

  return `${day}${daySuffix} ${month} ${year} ${time.toUpperCase()}`;
}

export function getDate(dateString) {
  const date = dayjs(dateString, { format: 'YYYY-MM-DD HH:mm:ss' });
  const day = date.format('D');
  const month = date.format('MMM');
  const year = date.format('YYYY');
  const time = date.format('h:mm A');

  const daySuffix =
    day === '1' || day === '21' || day === '31'
      ? 'st'
      : day === '2' || day === '22'
        ? 'nd'
        : day === '3' || day === '23'
          ? 'rd'
          : 'th';


  return `${day}${daySuffix} ${month} ${year}`;
}

export const getDateRange = (startDate, endDate) => {
  const start = new Date(startDate?.split(' ')[0]);
  const end = new Date(endDate?.split(' ')[0]);
  const dateArray = [];

  while (start <= end) {
    dateArray.push(start.toISOString().split('T')[0]);
    start.setDate(start.getDate() + 1);
  }

  return dateArray;
};

export const getStateNameById = (stateId) => (dispatch, getState) => {
  const { master: { state } } = getState();
  const stateList = state.find((state) => state.id == stateId);
  return stateList?.value;
};

export const reverseDateString = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`;
};

export const getDistrictNameById = (id) => (dispatch, getState) => {
  const { master: { district } } = getState();
  const districtList = district.find((data) => data.id == id);
  return districtList?.value;
};


export const getDistrictNameByIds = (districtIds) => (dispatch, getState) => {
  const { master: { district } } = getState();
  if (districtIds.length > 1 && Array.isArray(districtIds)) {
    const districtList = districtIds && districtIds.map((id) => {
      const filterList = district.find((data) => data.id == id);
      if (filterList) {
        return filterList?.value;
      }
    });
    return districtList.join(", ");
  } else {
    const filterList = district.find((data) => data.id == districtIds);
    if (filterList) {
      return filterList?.value;
    }
  }
};

export const handleAnnexureText = (id) => (dispatch, getState) => {
  const { master } = getState();
  const selectedAnnexure = master.annexureList.find((annexure) => annexure.id == id);
  if (selectedAnnexure) {
    return selectedAnnexure.name
  }
};

export const getCountryNameById = (id) => (dispatch, getState) => {
  const { master } = getState();
  const selectedCountry = master.country.find((country) => country.id == id);
  if (selectedCountry) {
    return selectedCountry.value
  }
};

export const getCompanyTypeByName = (name) => (dispatch, getState) => {
  const { users } = getState();
  if (name) {
    const selectedCompany = Object.keys(users?.companyTypes).length !== 0 && users?.companyTypes?.find((type) => type?.key === name);
    if (selectedCompany) {
      return selectedCompany.value
    }
  }
};


export const getConnectionTypeByName = (name) => (dispatch, getState) => {
  const { master: { connectionTypes } } = getState();
  if (name) {
    const selectedCompany = Object.keys(connectionTypes).length !== 0 && connectionTypes?.find((type) => type?.key === name);
    if (selectedCompany) {
      return selectedCompany.value
    }
  }
};

export const getCompanyTypeByKey = (name) => (dispatch, getState) => {
  const { master } = getState();
  if (name) {
    const selectedCompany = Object.keys(master?.connectionTypes).length !== 0 && master?.connectionTypes?.find((type) => type?.key === name);
    if (selectedCompany) {
      return selectedCompany.value
    }
  }
};

export const getCompanyUserTypeByKey = (name) => (dispatch, getState) => {
  const { users } = getState();
  if (name) {
    const selectedCompany = users?.companyTypes.length !== 0 && users?.companyTypes?.find((type) => type?.key === name);
    if (selectedCompany) {
      return selectedCompany.value
    }
  }
};

export const getThematicNameById = (id) => (dispatch, getState) => {
  const { master } = getState();
  const selectedThematic = master.thematicAreas.find((thematic) => thematic.id == id);
  if (selectedThematic) {
    return selectedThematic.value
  }
};

export const getReactionIdByName = (key) => (dispatch, getState) => {
  const { master } = getState();
  const selectedReaction = master.postReaction.find((reaction) => reaction.key == key);
  if (selectedReaction) {
    return selectedReaction.id
  }
};

export const getLanguageNameById = (id) => (dispatch, getState) => {
  const { master } = getState();
  const selectedLanguage = master.langauge?.find((language) => language?.id == id);
  if (selectedLanguage) {
    return selectedLanguage.value
  }
};

export function getFormatDate(dateString, initialFormat, finalFormat) {
  const parts = dateString.split(initialFormat);
  const day = parts[0];
  const month = parts[1];
  const year = parts[2];

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};
export function formatDateToDisplay(dateString) {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();

  return `${getOrdinalSuffix(day)} ${month} ${year}`;
}

export function formatDateTimeToDisplay(dateTimeString) {
  if (!dateTimeString) {
    return '';
  }

  const dateTime = new Date(dateTimeString);

  // Extract date components
  const day = dateTime.getDate();
  const month = dateTime.toLocaleString('default', { month: 'short' });
  const year = dateTime.getFullYear();

  // Extract time components
  let hours = dateTime.getHours();
  const minutes = dateTime.getMinutes().toString().padStart(2, '0');
  const meridiem = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  // Create the formatted string
  const formattedDateTime = `${getOrdinalSuffix(day)} ${month} ${year} ${hours}:${minutes} ${meridiem}`;

  return formattedDateTime;
}

export function formatTimeToDisplay(dateTimeString) {
  if (!dateTimeString) {
    return '';
  }

  const dateTime = new Date(dateTimeString);

  // Extract date components
  const day = dateTime.getDate();
  const month = dateTime.toLocaleString('default', { month: 'short' });
  const year = dateTime.getFullYear();

  // Extract time components
  let hours = dateTime.getHours();
  const minutes = dateTime.getMinutes().toString().padStart(2, '0');
  const meridiem = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  // Create the formatted string
  const formattedDateTime = `${hours}:${minutes} ${meridiem}`;

  return formattedDateTime;
}

function getOrdinalSuffix(day) {
  if (day > 10 && day < 20) {
    return day + 'th';
  } else {
    const lastDigit = day % 10;
    switch (lastDigit) {
      case 1:
        return day + 'st';
      case 2:
        return day + 'nd';
      case 3:
        return day + 'rd';
      default:
        return day + 'th';
    }
  }
}
export const formatDate = (dateStr) => {
  if (!dateStr) return;
  const [day, month, year] = dateStr.split('/');
  const date = new Date(`${month}/${day}/${year}`);

  if (isNaN(date)) {
    return 'Invalid Date';
  }
  const formattedDate = `${getOrdinalSuffix(day)} ${date.toLocaleString('default', { month: 'short' })} ${year}`;
  return formattedDate;
};

export function formatOnlyDate(dateStr) {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const date = new Date(dateStr);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export const encodeHTMLEntities = (text) => {
  var textArea = document.createElement('textarea');
  textArea.innerText = text;
  return textArea.innerHTML;
}

export const decodeHTMLEntities = (text) => {
  var textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}
export const convertUrlsToLinks = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
};
// export const decodeMultipleEncodedHTMLEntities = (text) => {
//   let decoded = text;

//   // Decode up to 3 times to handle triple encoding
//   for (let i = 0; i < 3; i++) {
//     decoded = decodeHTMLEntities(decoded);
//   }

//   return decoded;
// };
export const decodeMultipleEncodedHTMLEntities = (text) => {
  const decodeOnce = (text) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  };

  let decodedText = text;
  let previousText = "";

  while (decodedText !== previousText) {
    previousText = decodedText;
    decodedText = decodeOnce(decodedText);
  }

  return decodedText;
};

export const editorTextLength = value => {
  // if (value.replace(/<\/?[^>]+(>|$)/g, "").length > 2000) {
  //   return true;
  // }
  // return false;
  let divContainer = document.createElement("div");
  divContainer.innerHTML = value;
  let inputLength = divContainer.textContent.length || divContainer.innerText.length || 0
  if (inputLength > 2000) {
    return true;
  }
  return false;
}
export const getCorrectFormatDate = (dateString, dateSeparator = '/') => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${day}${dateSeparator}${month}${dateSeparator}${year}`;
};
export const getCorrectFormatDateTime = (dateString, dateSeparator = '/') => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  // Extract time components
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const meridiem = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  return `${day}${dateSeparator}${month}${dateSeparator}${year} ${hours}:${minutes}${meridiem}`;
};

export const getResponseDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const dayWithSuffix = day + (
    day === 1 || day === 21 || day === 31 ? 'st' :
      day === 2 || day === 22 ? 'nd' :
        day === 3 || day === 23 ? 'rd' :
          'th'
  );

  const monthAbbreviation = monthNames[monthIndex];

  return `${dayWithSuffix} ${monthAbbreviation} ${year}`;
};

export function formatDateToMonthYear(dateStr) {
  if (dateStr) {
    // Convert the date string to a Date object
    const date = new Date(dateStr);

    // Define options for formatting the date
    const options = { year: 'numeric', month: 'long' };

    // Format the date
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }
}

export function getCookie(cname) {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return decodeURIComponent(c.substring(name.length, c.length));
    }
  }
  return '';
}
export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${encodeURIComponent(cvalue)};${expires};path=/`;
}

export const formatDateTimeString = (dateString) => {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Helper function to get the day with the correct suffix
  function getDayWithSuffix(day) {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }

  // Extract components
  const day = date.getUTCDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getUTCFullYear();

  // Extract and format time in 12-hour format
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const formattedHours = hours % 12 || 12; // Convert to 12-hour format
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Combine to get the final formatted string
  return `${getDayWithSuffix(day)} ${month} ${year} ${formattedHours}:${formattedMinutes}`;
}

export function deleteCookie2(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + 0 * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}="";${expires};path=/`;
}
export function deleteCookie(name) {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

}

export function getFileURL(fileHost, fileName) {
  return `${fileHost}${fileName}`
}

export function setLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function getLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function deleteLocalStorage(key) {
  localStorage.removeItem(key);
}

export function seo(data = {}) {
  data.title = data.TITLE || 'bvokal - Social Platform for CSR Company, NGO, CSR consultant';
  data.metaDescription = data.Description || 'Social network for corporate social responsibility of corporates, Donors, foundations, implementing partners to share CSR services, volunteering activities etc';

  document.title = data.title;
  document.querySelector('meta[name="description"]').setAttribute('content', data.Description);
}
export const formatTime = (isoString) => {
  const date = new Date(isoString);
  const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  return formattedTime;
};

export const moveToSocialMedia = () => {
  const host = window.location.origin;
  const redirectURL = `${host}/social-media`;
  window.location.replace(redirectURL);
}
export const moveToPageNotFound = () => {
  const host = window.location.origin;
  const redirectURL = `${host}/page-not-found`;
  window.location.replace(redirectURL);
}
export const wrapText = (text) => {
  const limit = 36;
  const wrappedText = [];
  for (let i = 0; i < text.length; i += limit) {
    wrappedText.push(text.substr(i, limit));
  }
  return wrappedText.join('\n');
};
export const formatNumber = (value) => {
  if (value >= 1e9) {
    return (value / 1e9)?.toFixed(2) + ' Cr';
  } else if (value >= 1e6) {
    return (value / 1e6)?.toFixed(2) + ' M';
  } else if (value >= 1e5) {
    return (value / 1e5)?.toFixed(2) + ' L';
  } else if (value >= 1e4) {
    return (value / 1e3)?.toFixed(2) + ' K';
  } else {
    return value >= 10000 ? (value / 1e3)?.toFixed(2) + ' K' : value?.toString();
  }
};

export function calculateTimeRemaining(startTime) {
  const eventStartTime = new Date(startTime).getTime();
  const currentTime = new Date().getTime();
  let timeDiff = eventStartTime - currentTime;
  if (timeDiff < 0) {
    timeDiff = 0;
  }
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

  // Pad single-digit values with a leading zero
  const padWithZero = (value) => {
    return value < 10 ? '0' + value : value;
  };

  return {
    days: padWithZero(days),
    hours: padWithZero(hours),
    minutes: padWithZero(minutes),
    seconds: padWithZero(seconds)
  };
}
export function calculateTimeRemainingToEnd(endTime) {
  const eventEndTime = new Date(endTime).getTime();
  const currentTime = new Date().getTime();
  let timeDiff = eventEndTime - currentTime;
  if (timeDiff < 0) {
    timeDiff = 0;
  }
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

  // Pad single-digit values with a leading zero
  const padWithZero = (value) => {
    return value < 10 ? '0' + value : value;
  };

  return {
    days: padWithZero(days),
    hours: padWithZero(hours),
    minutes: padWithZero(minutes),
    seconds: padWithZero(seconds)
  };
}
export function calculateEventDuration(startTime, endTime) {
  const eventStartTime = new Date(startTime).getTime();
  const eventEndTime = new Date(endTime).getTime();
  let duration = eventEndTime - eventStartTime;
  if (duration < 0) {
    duration = 0;
  }
  const days = Math.floor(duration / (1000 * 60 * 60 * 24));
  const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((duration % (1000 * 60)) / 1000);

  // Pad single-digit values with a leading zero
  const padWithZero = (value) => {
    return value < 10 ? '0' + value : value;
  };

  return {
    days: padWithZero(days),
    hours: padWithZero(hours),
    minutes: padWithZero(minutes),
    seconds: padWithZero(seconds)
  };
}

export const getInitials = (name) => {
  if (name) {
    // Split the name by space and filter out any empty strings
    const words = name.split(' ').filter(word => word.length > 0);
    if (words?.length === 1) {
      // Only one word name
      return name?.slice(0, 1)?.toUpperCase();
    } else {
      // Two or more words name
      return (words[0][0] + words[1][0])?.toUpperCase();
    }
  }
};
export function timeToMinutes(time) {
  const [hoursStr, minutesStr, ampm] = time.split(/:| /);
  let hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  if (ampm.toLowerCase() === 'pm' && hours !== 12) {
    hours += 12; // Convert PM hours to 24-hour format
  } else if (ampm.toLowerCase() === 'am' && hours === 12) {
    hours = 0; // Convert 12:XX AM to 0:XX
  }

  return hours * 60 + minutes; // Convert hours and minutes to total minutes
}
export const generateTimeOptions = () => {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const time = new Date(0, 0, 0, hour, minute);
      let name = time.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
      let id = time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).replace("24", "00");
      options.push({
        name,
        id
      });
    }
  }
  return options;
};

export const generateTimeOptionsBy5 = () => {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 5) {
      const time = new Date(0, 0, 0, hour, minute);
      let name = time.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
      let id = time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).replace("24", "00");
      options.push({
        name,
        id
      });
    }
  }
  return options;
};

export const formatTimeData = (date) => {
  const time = new Date(`1970-01-01T${date}`);
  return time.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
}
export const formatDateMonth = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });

  return `${day} ${month}`;
};
export function convertTo24Hour(time12h) {
  const [time, period] = time12h.split(' ');
  let [hours, minutes] = time.split(':');
  hours = parseInt(hours);
  minutes = parseInt(minutes);
  if (period.toUpperCase() === 'PM' && hours !== 12) {
    hours += 12;
  } else if (period.toUpperCase() === 'AM' && hours === 12) {
    hours = 0;
  }
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

export function formatInterger(number) {
  if (Number.isInteger(number)) {
    return number.toString() + ":00";
  } else {
    return number.toFixed(2);
  }
}

export function kbToMb(kb) { const mb = kb / 1024; return mb; } // Example usage const sizeInKb = 2048; const sizeInMb = kbToMb(sizeInKb); console.log(`${sizeInKb} KB is equal to ${sizeInMb} MB`);

export function convertToLeadingZeros(number) {
  // Split the number into integer and fractional parts
  const [integerPart, fractionalPart] = number.toString().split('.');

  // Pad the integer part with leading zeros
  const paddedIntegerPart = integerPart.padStart(2, '0');

  // Return the concatenated string
  return `${paddedIntegerPart}.${fractionalPart}`;
}
// utils/common.js

export const formatEventDateTime = (startDate, startTime, endTime) => {
  if (startDate === undefined || startTime === undefined || endTime === undefined) {
    return "";
  }
  // Parse the start and end times
  const startDateTime = new Date(startDate + ' ' + startTime);
  const endDateTime = new Date(startDate + ' ' + endTime);

  // Format the date
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(startDateTime);

  // Format the time
  const formattedStartTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(startDateTime);

  const formattedEndTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(endDateTime);
  return `${formattedDate}, ${formattedStartTime}-${formattedEndTime}`;
};
export const formatEventStartDateTime = (startDate, startTime) => {
  if (startDate === undefined || startTime === undefined) {
    return "";
  }
  // Parse the start and end times
  const startDateTime = new Date(startDate + ' ' + startTime);

  // Format the date
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(startDateTime);

  // Format the time
  const formattedStartTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(startDateTime);

  return `${formattedDate}, ${formattedStartTime}`;
};

export const calculateTimeDifference = (EventStartTime, EventEndTime) => {
  if (EventStartTime === undefined || EventEndTime === undefined) {
    return '';
  }

  // Convert start and end times to Date objects if they are not already
  const startTime = typeof EventStartTime === 'string' ? new Date(`2000-01-01T${EventStartTime}`) : EventStartTime;
  const endTime = typeof EventEndTime === 'string' ? new Date(`2000-01-01T${EventEndTime}`) : EventEndTime;

  // Calculate the time difference in milliseconds
  const timeDifference = endTime.getTime() - startTime.getTime();

  // Convert milliseconds to hours with decimal precision
  const hoursDifference = timeDifference / (1000 * 60 * 60);

  // Format the result with two decimal places
  return `${hoursDifference.toFixed(2)} hrs`;
};

export const getDayFromDate = (dateString) => {
  const dateObj = new Date(dateString);
  return dateObj.getDate();
}

export const getMonthFromDate = (dateString) => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const dateObj = new Date(dateString);
  const monthIndex = dateObj.getMonth();
  return monthNames[monthIndex];
}

export const formatDateString = (dateString) => {
  const date = new Date(dateString);
  const options = { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
}

export const formatDateString2 = (dateString) => {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Define arrays for weekdays and months
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Get the components of the date
  const weekday = weekdays[date.getUTCDay()];
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  // Format the date string
  return `${weekday}, ${day} ${month} ${year}`;
}

export const gerCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export const getCurrentTime = () => {
  const currentDate = new Date();
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const currentTime = `${hours}:${minutes}`;
  return currentTime;
}
export function getMemberDate(value, initialFormat, finalFormat) {
  const parts = value.split(" ");
  const datePart = parts[0]; // Date part: "2024-03-14"
  const [year, month, day] = datePart.split("-");
  // Combine date in final format
  const finalDate = `${day}${finalFormat}${month}${finalFormat}${year}`;

  return finalDate;
}

export const timeFormat = (timeString) => {
  if (!timeString) return null;

  const [hour, minute] = timeString.split(':');
  return `${hour}:${minute}`;
};
export const timeAgo = (postedTime) => {
  if (!postedTime) {
    return '0 sec ago';
  }

  const now = new Date();
  const postedDate = new Date(postedTime);


  const timeDifferenceInSeconds = Math.floor((now - postedDate) / 1000);
  if (timeDifferenceInSeconds < 0) {
    return '0 sec ago';
  } else if (timeDifferenceInSeconds < 60) {
    return `${timeDifferenceInSeconds} sec ago`;
  } else if (timeDifferenceInSeconds < 3600) {
    const minutes = Math.floor(timeDifferenceInSeconds / 60);
    return `${minutes} min ago`;
  } else if (timeDifferenceInSeconds < 86400) {
    const hours = Math.floor(timeDifferenceInSeconds / 3600);
    return `${hours} hrs ago`;
  } else if (timeDifferenceInSeconds < 2592000) {
    const days = Math.floor(timeDifferenceInSeconds / 86400);
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (timeDifferenceInSeconds < 31536000) {
    const months = Math.floor(timeDifferenceInSeconds / 2592000);
    return `${months} month${months > 1 ? 's' : ''} ago`;
  } else {
    const years = Math.floor(timeDifferenceInSeconds / 31536000);
    return `${years} year${years > 1 ? 's' : ''} ago`;
  }
};

// export function convertMentionsToLinks(text, className) {
//   // Regular expression to match @[User Name](username)
//   const mentionPattern = /@\[(.*?)\]\((.*?)\)/g;

//   // return text;
//   // Replace mentions with HTML anchor tags
//   return text.replace(mentionPattern, (match, displayName, username) => {
//     return `<a href="/profile/${username}" class="${className}">${displayName}</a>`;
//   });
// }
export function convertMentionsToLinks(text, className) {
  // Ensure text is a string
  const stringText = typeof text === 'string' ? text : String(text);

  // Regular expression to match @[User Name](username)
  const mentionPattern = /@\[(.*?)\]\((.*?)\)/g;

  // Replace mentions with HTML anchor tags
  return stringText.replace(mentionPattern, (match, displayName, username) => {
    return `<a href="/profile/${username}" class="${className}">${displayName}</a>`;
  });
}

// export const convertHashTagToLink = (text, className ) => {
//   return text.replace(/#\[#([^\]]+)\]/g, `<span class="${className}"><a href="/social-media/hashtags/$1" class="${className}">#$1</a></span>`);
// };
// export const convertHashTagToLink = (text, className) => {
//   return text.replace(/#\[#([^\]]+)\]/g, (_, hashtag) => {
//     return `<span class="${className}"><a href="/social-media/hashtags/${hashtag}" class="${className}" onclick="handleHashtagClick(event, '${hashtag}')">#${hashtag}</a></span>`;
//   });
// };

// export const convertHashTagToLink = (text, className) => {
//   // handle the #[hashtag] format
//   // let updatedText = text.replace(/#\[#([^\]]+)\]/g, (_, hashtag) => {
//     let updatedText = text.replace(/(?<=\s|^)#\[#([^\]]+)\]/g, (_, hashtag) => {
//     return `<span class="${className}"><a href="/social-media/hashtags/${hashtag}" class="${className}" onclick="handleHashtagClick(event, '${hashtag}')">#${hashtag}</a></span>`;
//   });

//   // handle the #hashtag format
//   //updatedText = updatedText.replace(/#(\w+)/g, (_, hashtag) => {
//   updatedText = updatedText.replace(/(?<=\s|^)#(\w+)/g, (match, hashtag) => {
//     return `<span class="${className}"><a href="/social-media/hashtags/${hashtag}" class="${className}" onclick="handleHashtagClick(event, '${hashtag}')">#${hashtag}</a></span>`;
//   });

//   return updatedText;
// };
export const convertHashTagToLink = (text, className) => {
  // Handle #[hashtag] format only if preceded by a space or at the start of the string
  let updatedText = text.replace(/(?<=\s|^)#\[#([^\]]+)\]/g, (_, hashtag) => {
    return `<span class="${className}"><a href="/social-media/hashtags/${hashtag}" class="${className}" onclick="handleHashtagClick(event, '${hashtag}')">#${hashtag}</a></span>`;
  });

  // Handle #[hashtag] format without space - sanitize to #hashtag
  updatedText = updatedText.replace(/#\[#([^\]]+)\]/g, (_, hashtag) => {
    return `#${hashtag}`;
  });

  // Handle #hashtag format only if preceded by a space or at the start of the string
  updatedText = updatedText.replace(/(?<=\s|^)#(\w+)/g, (match, hashtag) => {
    return `<span class="${className}"><a href="/social-media/hashtags/${hashtag}" class="${className}" onclick="handleHashtagClick(event, '${hashtag}')">#${hashtag}</a></span>`;
  });

  return updatedText;
};


export function isJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export function convertJSONparse(str) {
  // Assuming str is a comma-separated string that needs to be converted to an array
  if (typeof str === 'string') {
    return str.split(',');
  }
  return [];
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

// Debounce utility function
export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const formatDatesRange = (dates) => {
  if (dates?.length === 0) return '';

  const months = [
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const startDate = formatDate(dates[0]);
  const endDate = dates?.length > 1 ? formatDate(dates[dates?.length - 1]) : '';

  if (endDate) {
    const startDay = new Date(dates[0]).getDate();
    const endDay = new Date(dates[dates?.length - 1]).getDate();
    const month = months[new Date(dates[0]).getMonth()];
    const year = new Date(dates[0]).getFullYear();

    return `${startDay} - ${endDay} ${month} ${year}`;
  } else {
    return startDate;
  }
};

export const formatDateRange = (dates) => {
  if (!dates || dates.length === 0) return '';

  // Parse the first and last date
  const startDate = new Date(dates[0]);
  const endDate = new Date(dates[dates.length - 1]);

  // Format options
  const optionsWithYear = { day: 'numeric', month: 'short', year: 'numeric' };
  const optionsWithoutYear = { day: 'numeric', month: 'short' };
  const yearFormatter = new Intl.DateTimeFormat('en-GB', optionsWithYear);
  const noYearFormatter = new Intl.DateTimeFormat('en-GB', optionsWithoutYear);

  // Formatted date strings
  const startFormattedWithYear = yearFormatter.format(startDate);
  const startFormatted = noYearFormatter.format(startDate);
  const endFormatted = noYearFormatter.format(endDate);

  // If only one date, return just that date with the year
  if (dates.length === 1) {
    return startFormattedWithYear;
  }

  // Return the formatted date range
  return `${startFormatted} - ${endFormatted} ${startDate.getFullYear()}`;
};

export const convertDate = (dateString) => {
  // Extract the date part from the input string
  const datePart = dateString.split(' ')[0];

  // Split the date part into year, month, and day
  const [year, month, day] = datePart.split('-');

  // Rearrange the date to DD/MM/YYYY format
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

export const parseEmptySpaces = (value) => {
  if (typeof value === 'string' && value.trim() === '') {
    return undefined;
  }
  return value;
  // return value.trim();
};
export const formatAmount = (amount) => {
  if (amount >= 10000000) {
    return (amount / 10000000).toFixed(2) + ' crore';
  } else if (amount >= 100000) {
    return (amount / 100000).toFixed(2) + ' lac';
  } else {
    return amount?.toString();
  }
};
const stripHtmlTags = (html) => {
  return html.replace(/<\/?[^>]+(>|$)/g, "");
};
const decodeHTMLEntit = (text) => {
  let decodedString = text;
  const parser = new DOMParser();
  while (decodedString.includes('&')) {
    decodedString = parser.parseFromString(decodedString, 'text/html').documentElement.textContent;
  }
  return decodedString;
};
export const getTextFromHtml = (html) => {
  const decodedHtml = decodeHTMLEntit(html);
  return stripHtmlTags(decodedHtml);
};

// export const obfuscateEmail = (email) => {
//   const [username, domain] = email.split('@');
//   if (username.length <= 2) {
//       return email; 
//   }
//   const obfuscatedUsername = username[0] + '**' + username[username.length - 1];
//   return `${obfuscatedUsername}@${domain}`;
// };
export const obfuscateEmail = (email) => {
  const [username, domain] = email.split('@');

  if (username.length <= 4) {
    return email;
  } else {
    const obfuscatedUsername = username.slice(0, 2) + '*'.repeat(username.length - 4) + username.slice(-2);
    return `${obfuscatedUsername}@${domain}`;
  }
};

export const formatTimeSeconds = (time) => { // format to get time in sec 00:24
  const minutes = String(Math.floor(time / 60)).padStart(2, '0');
  const seconds = String(time % 60).padStart(2, '0');
  return `${minutes}:${seconds}`;
};

export const isPastNominationDate = (nominationLastDateStr) => {
  // Parse the nomination last date string
  const nominationLastDate = new Date(nominationLastDateStr);

  // Set the time to 00:00:00 for the nomination last date
  nominationLastDate.setHours(0, 0, 0, 0);

  // Get the current date
  const currentDate = new Date();

  // Set the time to 00:00:00 for the current date
  currentDate.setHours(0, 0, 0, 0);

  // Compare the dates
  if (currentDate > nominationLastDate) {
    return true;  // Current date is after the nomination date
  }
  return false;  // Current date is before or on the nomination date
}