import { Box, CircularProgress, Fade, Grid, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import theme from '../../../../assets/material-ui/theme'
import CommonButton2 from '../../../../common/components/CommonButton2'
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import CommonInitials from '../../../../common/components/CommonInitials';
import { getAllSponsorInvitations, sponsorAction } from '../../actions';
import { convertDate, debounce, getCompanyTypeByKey, getInitials } from '../../../../common/utils';
import _ from 'lodash-es';
import SocialMediaEventCSS from '../../SocialMediaCSS/SocialMediaEvent.module.css';
import { SPEKAER_TABS } from '../../../../common/constants';
import AddressIcon from '../../../../assets/icons/Address.svg';
import slugify from 'slugify';

const SponsorInvitations = ({ dispatch, navigate, sponsorList, totalSponsors, offset, containerRef, selectedConnTab }) => {
  const [loading, setLoading] = useState(false);
  const isFetching = useRef(false);
  const lastScrollTop = useRef(0);
  let totalCount = useRef(0);
  let totalData = useRef(0);

  useEffect(() => {
    if (sponsorList) {
      totalCount.current = sponsorList.length;
      totalData.current = totalSponsors;
    }
  }, [sponsorList, totalSponsors])

  const handleScroll = useCallback(() => {
    if (isFetching.current) return;
    const container = containerRef.current;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    if (scrollTop > lastScrollTop.current && scrollTop + clientHeight >= scrollHeight - 50 && totalCount.current < totalData.current) { // Adjusted threshold for triggering
      fetchMoreData();
    }

    lastScrollTop.current = scrollTop;
  }, []);

  const debouncedHandleScroll = debounce(handleScroll, 300);
  const fetchMoreData = () => {
    try {
      isFetching.current = true;
      offset.current += 10;
      dispatch(getAllSponsorInvitations(offset.current, true, setLoading, isFetching));
    } catch (error) {
      isFetching.current = false;
    }
  };
  useEffect(() => {
    dispatch(getAllSponsorInvitations());
  }, [])

  const handleAccept = (gid, id) => {
    dispatch(sponsorAction(gid, id, 'accepted'));
  }

  const handleDeny = (gid, id) => {
    dispatch(sponsorAction(gid, id, 'declined'));
  }

  const handleWithdraw = (gid, id) => {
    dispatch(sponsorAction(gid, id, 'withdraw'));
  }

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('scroll', debouncedHandleScroll);
    return () => {
      container.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, []);

  const goToConference = (event, data) => {
    const clickedElement = event.target;
    const isButton = clickedElement.tagName === "BUTTON" || clickedElement.closest("button");
    if (!isButton) {
      const slug = slugify(data?.title, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
      const slugUrl = `${slug}-${data?.conference_gid}`;

      navigate(`/volunteer/${slugUrl}`);
    }
  }

  return (
    <Grid item xs={12}>
      <Box className={SocialMediaCSS.communityMainCardGrid}>
        <Grid container spacing={2}>
          {!_.isEmpty(sponsorList) && sponsorList.map((data) => (
            <Grid item xs={12} md={12} sm={12} key={data?.gid}>
              <Box className={SocialMediaCSS.speakerConnMainCard} onClick={(e) => goToConference(e, data)}>
                <Box className={SocialMediaCSS.speakerConnMainBox}>
                  <img src={data?.conference_image} alt='logo' className={SocialMediaCSS.speakerConnMainLogo} />
                  <Box className={SocialMediaCSS.speakerConnMainBox2}>
                    <Box className={SocialMediaCSS.speakerConnMainBox3}>
                      <Typography className={SocialMediaCSS.speakerConnTypo}>{data?.title}</Typography>
                      <Typography className={SocialMediaCSS.speakerConnTypo2}>Organizer: {data?.organizer}</Typography>
                      <Box className={SocialMediaCSS.speakerConnModeBox}>
                        <img src={data?.mode_type === 'online' ? data?.conference_platform?.image : AddressIcon} alt="Event Icon" className={SocialMediaCSS.h14} />
                        <Typography className={SocialMediaCSS.speakerConnTypo3}>{data?.mode_type === 'online' ? data?.conference_platform?.title : data?.address}</Typography>
                      </Box>
                      <Typography className={SocialMediaCSS.speakerConnTypo4}>{`${convertDate(data?.start_date)} - ${convertDate(data?.end_date)}`}</Typography>
                    </Box>
                    <Box className={SocialMediaCSS.speakerConnMainBox4}>
                      {selectedConnTab === SPEKAER_TABS.RECIEVED || selectedConnTab === SPEKAER_TABS.MISSED ?
                        <>
                          <CommonButton2
                            label="Accept"
                            name="Accept"
                            type="Accept"
                            variant="contained"
                            // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                            style={{ padding: '2px 16px', backgroundColor: data?.conference_status === 'completed' ? '' : theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, boxShadow: 'none', width: '100px', borderRadius: '20px' }}
                            onClick={() => { handleAccept(data?.gid, data?.conference_gid) }}
                            disabled={data?.conference_status === 'completed'}
                          />
                          <CommonButton2
                            label='Decline'
                            name='Decline'
                            type='Decline'
                            variant="outlined"
                            // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                            style={{ padding: '2px 16px', color: data?.conference_status === 'completed' ? '' : theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, borderColor: data?.conference_status === 'completed' ? '' : theme.mainPalette.primary.primary110, width: '100px', borderRadius: '20px' }}
                            onClick={() => handleDeny(data?.gid, data?.conference_gid)}
                            disabled={data?.conference_status === 'completed'}
                          />
                        </> : selectedConnTab !== SPEKAER_TABS.DECLINED &&
                        <CommonButton2
                          label='Withdraw'
                          name='Withdraw'
                          type='Withdraw'
                          variant="outlined"
                          // disabled={pastProjectData.length >= PAST_PROJECT_LENGTH}
                          style={{ padding: '2px 16px', color: data?.conference_status === 'completed' ? '' : theme.mainPalette.primary.primary110, fontSize: theme.typography.fontSize, borderColor: data?.conference_status === 'completed' ? '' : theme.mainPalette.primary.primary110, width: '100px', borderRadius: '20px' }}
                          onClick={() => handleWithdraw(data?.gid, data?.conference_gid)}
                          disabled={data?.conference_status === 'completed'}
                        />
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {loading && <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? '100ms' : '0ms',
          }}
          unmountOnExit
        >
          <Box className={SocialMediaEventCSS.loadingBox}>
            <CircularProgress />
          </Box>
        </Fade>
      </Box>}
      {_.isEmpty(sponsorList) && <Box className={`${SocialMediaEventCSS.f} ${SocialMediaEventCSS.jc_center}`} sx={{ m: '230px 0px', width: '100%' }}>
        <Typography sx={{ lineHeight: '36px', fontSize: '30px', fontWeight: 500, color: '#666666' }}>All Caught up!!</Typography>
      </Box>}
    </Grid>
  )
}

export default SponsorInvitations