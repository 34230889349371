import React, { useEffect, useRef, useState } from 'react'
import { getConnectsCount, getPostTypes } from '../../../../common/actions';
import { getAllConnection, getInvitations, getMasterData, getSuggestions, readAllNotifications } from '../../actions';
import { useLocation, useNavigate } from 'react-router';
import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import SocialMediaCSS from '../../SocialMediaCSS/SocialMedia.module.css';
import CustomSnackbar from '../../../../common/components/CommonSnackbar';
import MainHeader from '../../../../common/components/MainHeader';
import { useDispatch } from 'react-redux';
import RightChatSideBar from '../Chats/RightChatSideBar';
import CommunityMain from './CommunityMain';
import { setConnectionUserList, setSelectedConnTab, setTotalAcceptedCount, setTotalRequestedCount } from '../../../../store/slices/SocialMediaSlice';
import { CONNECTION_TABS, PROFILE_TYPE } from '../../../../common/constants';

const CommunityLayout = () => {
    const snackbarProps = useSelector((state) => state.message);
    const { users, socialMedia: { selectedConnTab, myInvitations, mySuggestions, recommendationList, totalRecommendationCount, invitationList, totalInvitationCount, connectionCount, connectionUserList, totalConnectionListCount, volInvitationList, totalVolInvitationCount, totalRequestedCount, totalAcceptedCount, speakerList, totalSpeakers, sponsorList, totalSponsors, totalMissedCount, totalDeclinedCount }, master: { connectionTypes } } = useSelector((state) => state);
    const [selectedCard, setSelectedCard] = useState('');
    const offset = useRef(10);
    const isIndividual = users?.userData?.profile_type?.toLowerCase() !== PROFILE_TYPE.COMPANY ? true : false;
    const containerRef = useRef(null);
    const location = useLocation();
    const volInvite = location?.state?.volInvite;
    const speaker = location?.state?.speaker;
    const sponsor = location?.state?.sponsor;
    console.log("read", volInvite)
    useEffect(() => {
        if (volInvite) {
            setSelectedCard('volinvite');
        }
        if (speaker) {
            setSelectedCard('speaker');
        }
        if (sponsor) {
            setSelectedCard('sponsor');
        }
    }, []);
    const handleCardClick = (value) => {
        offset.current = 10;
        dispatch(setSelectedConnTab(''));
        setSelectedCard(value);
        dispatch(setConnectionUserList([]));
        dispatch(setTotalRequestedCount(0));
        dispatch(setTotalAcceptedCount(0));
        if (value === 'connect') {
            dispatch(getAllConnection());
        } else if (value === 'independent_ngo') {
            dispatch(setSelectedConnTab(CONNECTION_TABS.INDIPENDENT_NGO));
        } else if (value === 'section8') {
            dispatch(setSelectedConnTab(CONNECTION_TABS.SECTION8));
        } else if (value === 'foundation') {
            dispatch(setSelectedConnTab(CONNECTION_TABS.FOUNDATION));
        } else if (value === 'public_company') {
            dispatch(setSelectedConnTab(CONNECTION_TABS.PUBLIC));
        } else if (value === 'private') {
            dispatch(setSelectedConnTab(CONNECTION_TABS.PRIVATE));
        }
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isMdScreen = useMediaQuery('(max-width: 960px)');
    useEffect(() => {
        dispatch(getPostTypes());
        dispatch(getMasterData());
        dispatch(getInvitations());
        dispatch(getSuggestions());
        dispatch(getConnectsCount());
    }, [])
    return (
        <>
            <Box className={SocialMediaCSS.MainHeader}>
                <MainHeader />
            </Box>
            {Object.keys(snackbarProps)?.length > 0 && <Box textAlign='center' width='100%' ><CustomSnackbar /> </Box>}
            <Grid container className={SocialMediaCSS.bgGrey100} height="100vh" overflow={'hidden'} >
                <Grid item xs={12} className={SocialMediaCSS.mainContent} sx={{ overflow: 'auto' }} ref={containerRef}>
                    <Container maxWidth={'xl'}>
                        <Grid container spacing={3}>
                            {!isMdScreen &&<Grid item xs={3} md={3}>
                                <RightChatSideBar isIndividual={isIndividual} showCommunity={true} selectedCard={selectedCard} handleCardClick={handleCardClick} />
                            </Grid>}
                            <Grid item xs={isMdScreen ? 12:9} md={9} sx={{ pl: '16px !important' }}>
                                <CommunityMain dispatch={dispatch} navigate={navigate} selectedCard={selectedCard} handleCardClick={handleCardClick} connectionCount={connectionCount} selectedConnTab={selectedConnTab} myInvitations={myInvitations} mySuggestions={mySuggestions} recommendationList={recommendationList} offset={offset} totalRecommendationCount={totalRecommendationCount} invitationList={invitationList} totalInvitationCount={totalInvitationCount} connectionUserList={connectionUserList} totalConnectionListCount={totalConnectionListCount} volInvitationList={volInvitationList} totalVolInvitationCount={totalVolInvitationCount} containerRef={containerRef} totalRequestedCount={totalRequestedCount} totalAcceptedCount={totalAcceptedCount} speakerList={speakerList} totalSpeakers={totalSpeakers} sponsorList={sponsorList} totalSponsors={totalSponsors} totalMissedCount={totalMissedCount} totalDeclinedCount={totalDeclinedCount} />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </>
    )
}
export default CommunityLayout