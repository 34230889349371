import { Box, CircularProgress, Fade, Grid, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react';
import userImage from '../../../../assets/images/userImage.png';
import theme from '../../../../assets/material-ui/theme';
import CommonInitials from '../../../../common/components/CommonInitials';
import _ from 'lodash-es';
import { timeAgo } from '../../../../common/utils';
import { getNotificationData } from '../../actions';
import { setNotificationPostGid, setNotificationPostType } from '../../../../store/slices/SocialMediaSlice';
import { useSelector } from 'react-redux';
import { setDashboardTab } from '../../../../store/slices/RfpSlice';
import slugify from 'slugify';

const NotificationScreen = ({ SocialMediaCSS, notificationList, navigate, offset, totalNotificationCount, dispatch }) => {
    const { users: { userData } } = useSelector((state) => { return state; });
    const [isHovering, setIsHovering] = useState(null);
    const [loading, setLoading] = useState(false);
    let totalCount = useRef(0);
    let totalNotification = useRef(0);
    let isFecthing = false;
    const Role_Id = userData?.sub_user ? 2 : 1;

    useEffect(() => {
        // Add event listener when component mounts to detect scroll
        window.addEventListener('scroll', handleScroll);

        return () => {
            // Remove event listener when component unmounts
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (notificationList) {
            totalCount.current = notificationList.length;
            totalNotification.current = totalNotificationCount;
        }
    }, [notificationList, totalNotificationCount])

    const handleScroll = () => {
        // Detect if user has scrolled to the bottom of the page
        console.log('handleScroll triggered');
        if (isFecthing) return;
        if (totalCount.current < totalNotification.current) {
            console.log(totalCount.current, 'running')
            if (Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.scrollHeight) {
                // Fetch more data when user scrolls to the bottom
                try {
                    isFecthing = true;
                    offset.current = offset.current + 10;
                    if (totalCount.current) {
                        dispatch(getNotificationData(offset.current, true, setLoading));
                    }
                } catch (error) {
                    isFecthing = false;
                }
                setTimeout(() => {
                    isFecthing = false;
                }, 2000);
            }
        } else {
            setLoading(false);
        }
    };

    const handleImageHover = (index) => {
        setIsHovering(index);
    };

    const handleImageLeave = () => {
        setIsHovering(null);
    };
    const handleNavigation = (notification) => {
        const { notification_id, event_id, post_gid, rfp_gid, conference_title, event_gid, conference_gid, notifiable_type, notification_statement, post_id, actor_id } = notification;
        //console.log('Notification:', notification);
        switch (notifiable_type) {
            case 'ConnectionRequest':
                if (notification_statement === 'has sent you a connection request.') {
                    navigate(`/social-media/community`);
                } else if (notification_statement === 'accepted your connection request.') {
                    navigate(`/profile/${actor_id}`)
                }
                break;
            case 'CompanyVolunteer':
                let volInvite = "true";
                if (notification_statement === 'has invited you to join their volunteering program') {
                    navigate(`/social-media/community`, { state: { volInvite } });
                }
                break;
            case 'UserFollower':
                if (notification_statement === 'started following you.') {
                    navigate(`/profile/${actor_id}`);
                }
                break;
            case 'PostComment':
                if (notification_statement === 'commented on') {
                    navigate(`/social-media/posts/${post_gid}`);
                    dispatch(setNotificationPostType('posts'))
                    dispatch(setNotificationPostGid(post_gid))
                } else if (notification_statement === "commented on your post.") {
                    navigate(`/social-media/posts/${post_gid}`);
                    dispatch(setNotificationPostType('posts'))
                    dispatch(setNotificationPostGid(post_gid))
                }
                break;
            case 'Reaction':
                if (notification_statement === 'reacted to your post.') {
                    navigate(`/social-media/posts/${post_gid}`);
                    dispatch(setNotificationPostType('posts'))
                    dispatch(setNotificationPostGid(post_gid))
                } else if (notification_statement === 'reacted on') {
                    navigate(`/social-media/posts/${post_gid}`);
                    dispatch(setNotificationPostType('posts'))
                    dispatch(setNotificationPostGid(post_gid))
                }
                break;
            case 'CommentReply':
                if (notification_statement === 'has replied to a comment to your post.') {
                    navigate(`/social-media/posts/${post_gid}`);
                    dispatch(setNotificationPostType('posts'))
                    dispatch(setNotificationPostGid(post_gid))
                }
                break;
            case 'Post':
                if (notification_statement === 'has tagged you in post.') {
                    navigate(`/social-media/posts/${post_gid}`);
                    dispatch(setNotificationPostType('posts'))
                    dispatch(setNotificationPostGid(post_gid))
                } else if (notification_statement === 'has reshared your post.') {
                    navigate(`/social-media/posts/${post_gid}`);
                    dispatch(setNotificationPostType('posts'))
                    dispatch(setNotificationPostGid(post_gid))
                }
                break;
            case 'Message':
                if (notification_statement === 'send you a new message.') {
                    navigate(`/social-media/messages`);
                }
            case 'ProposalRequest':
                if (notification_statement === 'has published an RFP') {
                    if (Role_Id === 1) {
                        navigate(`/social-media/rfp/${rfp_gid}`);
                    } else if (Role_Id === 2) {
                        const sent = true;
                        navigate(`/rfp/preview/${rfp_gid}`, {
                            state: { sent },
                        });
                    }
                } else if (notification_statement === 'shared') {
                    if (Role_Id === 1) {
                        navigate(`/social-media/rfp/${rfp_gid}`);
                    } else if (Role_Id === 2) {
                        const sent = true;
                        navigate(`/rfp/preview/${rfp_gid}`, {
                            state: { sent }, // need to confirm this case
                        });
                    }
                } else if (notification_statement === 'sent you a RFP') {
                    const inbox = true;
                    navigate(`/rfp/preview/${rfp_gid}`, {
                        state: { inbox },
                    });
                } else if (notification_statement === "you have been assigned to") {
                    navigate(`/rfp/listing`);
                } else if (notification_statement === "you have received a response on") {
                    navigate(`/rfp/listing`);
                    dispatch(setDashboardTab("sent"))
                } else if (notification_statement === "has request a proposal on RFP") {
                    navigate(`/rfp/listing`);
                } else if (notification_statement === "has saved the rfp"){
                    navigate(`/rfp/listing`); 
                }
                break;
            case 'SocialEvent':
                if (notification_statement === 'has requested you approve the event.') {
                    navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === 'has been Approved and published your event.') {
                    navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === 'has published an event') {
                    navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === "has accepted your event.") {
                    navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === "has been declined your event.") {
                    navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === "has approved your event.") {
                    navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === "has been Approved by the admin and sent for partner approval.") {
                    navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === "you have been assigned to") {
                    navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === "unassign from event.") {
                    navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === "you have been unassigned from") {
                    // navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === "just shared your event") {
                    navigate(`/social-media/events/${event_gid}`);
                } else if (notification_statement === "has invited you to join their volunteering program") {
                    let volInvite = "true";
                    navigate(`/social-media/community`, { state: { volInvite } });
                } else if (notification_statement === "Your organization has published an event for") {
                    navigate(`/social-media/events/${event_gid}`);
                } else if (notification_statement === 'like your event') {
                    navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === 'shared') {
                    navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === 'has requested you approve the event.') {
                    navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === 'has requested you to join your event.') {
                    navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === 'has been declined by') {
                    navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === 'has decline your volunteer invitation.'){
                    navigate(`/events/eventpreview/${event_gid}`);
                } else if (notification_statement === 'has accepted your volunteer request.'){
                    navigate(`/social-media/events/${event_gid}`);  
                } 
                break;
            case 'Conference':
                if (notification_statement === 'has published a new conference.') {
                    const slug = slugify(conference_title, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
                    const slugUrl = `${slug}-${conference_gid}`;
                    navigate(`/volunteer/${slugUrl}`);
                } else if (notification_statement === 'has requested you to be a partner for their conference.') {
                    let sponsor = "true";
                    navigate(`/social-media/community`, { state: { sponsor } });
                } else if (notification_statement === 'has requested you to be a speaker for their conference.') {
                    let speaker = "true";
                    navigate(`/social-media/community`, { state: { speaker } });
                } else if (notification_statement === 'has accepted the speaker invitation for') {
                    const slug = slugify(conference_title, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
                    const slugUrl = `${slug}-${conference_gid}`;
                    navigate(`/volunteer/${slugUrl}`);
                } else if (notification_statement === 'has declined the speaker invitation for') {
                    const slug = slugify(conference_title, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
                    const slugUrl = `${slug}-${conference_gid}`;
                    navigate(`/volunteer/${slugUrl}`);
                } else if (notification_statement === 'has declined the parnter invitation for') {
                    const slug = slugify(conference_title, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
                    const slugUrl = `${slug}-${conference_gid}`;
                    navigate(`/volunteer/${slugUrl}`);
                } else if (notification_statement === 'has accepted the parnter invitation for') {
                    const slug = slugify(conference_title, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
                    const slugUrl = `${slug}-${conference_gid}`;
                    navigate(`/volunteer/${slugUrl}`);
                } else if (notification_statement === 'starts soon.') {
                    const slug = slugify(conference_title, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
                    const slugUrl = `${slug}-${conference_gid}`;
                    navigate(`/volunteer/${slugUrl}`);
                } else if (notification_statement === 'Your delegate request has been accepted for') {
                    const slug = slugify(conference_title, { lower: true, strict: true, remove: /[*+~.()'"!:@]/g });
                    const slugUrl = `${slug}-${conference_gid}`;
                    navigate(`/volunteer/${slugUrl}`);
                }
                break;
            default:
                console.log('Unknown notification type');
        }
    };

    return (
        <Grid xs={12} className={SocialMediaCSS.notificationRoot}>
            <Grid xs={12} container >
                <Grid item xs={12} className={SocialMediaCSS.notificationMain}>
                    <Box className={SocialMediaCSS.notificationMainBox}>
                        <Typography className={SocialMediaCSS.notificationMainTitle}>Notifications</Typography>
                    </Box>
                    <Box className={SocialMediaCSS.notificationSecondBox}>
                        <Box className={SocialMediaCSS.notificationSecondBox2}>
                            <Box className={SocialMediaCSS.notificationListMain}>
                                {!_.isEmpty(notificationList) && notificationList.map((notification) => {
                                    const remainingText = notification?.value?.slice(notification?.brand_name?.length); // Get the remaining text
                                    const notificationText = notification?.notification_statement;
                                    //console.log("hello",notificationText)
                                    return (
                                        <Box className={SocialMediaCSS.notificationListBox} sx={{ backgroundColor: isHovering === notification?.notification_id ? theme.mainPalette.primary.main : theme.mainPalette.grey.white }} onMouseEnter={() => handleImageHover(notification?.notification_id)} onMouseLeave={handleImageLeave}
                                            onClick={() => handleNavigation(notification)} >
                                            <CommonInitials
                                                name={notification?.profile_initials}
                                                bgColor={notification?.profile_colour_code}
                                                divStyle={{ padding: '3px', maxHeight: '48px', maxWidth: '48px', minHeight: '48px', minWidth: '48px', borderRadius: '50%', boxShadow: theme.shadow.softShadow, flex: '0 0 48px', borderColor: '#e9ecef', border: '1px solid #dee2e6', cursor: 'pointer' }}
                                                typoStyle={{ fontSize: '16px', lineHeight: '40px', }}
                                                avatarStyle={{ width: '48px', height: '48px', boxShadow: theme.shadow.softShadow, border: '1px solid #dee2e6', cursor: 'pointer' }}
                                                src={notification?.profile_logo?.url}
                                                companyLogo={notification?.profile_logo?.url}
                                                avatar={true}
                                                // onClick={() => navigate(`/profile/${notification?.actor_id}`)}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    navigate(`/profile/${notification?.actor_id}`);
                                                }}
                                            />
                                            {notificationText !== 'you have received a response on' && notificationText !== 'Your delegate request has been accepted for' && notificationText !== 'starts soon.' &&
                                            <Box className={SocialMediaCSS.notificationListTextBox}>
                                                <a className={SocialMediaCSS.notificationListText} style={{ color: isHovering === notification?.notification_id ? theme.mainPalette.grey.white : theme.mainPalette.grey.lightText, cursor: "pointer" }}>
                                                    <span style={{ fontWeight: 700 }} onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate(`/profile/${notification?.actor_id}`);
                                                    }}>{notification?.brand_name} </span>
                                                    {remainingText}
                                                </a>
                                            </Box>}
                                            {(notificationText === 'you have received a response on' || notificationText === 'Your delegate request has been accepted for' || notificationText === 'starts soon.') &&
                                            <Box className={SocialMediaCSS.notificationListTextBox}>
                                                <a className={SocialMediaCSS.notificationListText} style={{ color: isHovering === notification?.notification_id ? theme.mainPalette.grey.white : theme.mainPalette.grey.lightText, cursor: "pointer" }}>
                                                    {/* <span style={{ fontWeight: 700 }} onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate(`/profile/${notification?.actor_id}`);
                                                    }}>{notification?.brand_name} </span> */}
                                                    {/* {remainingText} */}
                                                   {notification?.value}
                                                </a>
                                            </Box>}
                                            <Box className={SocialMediaCSS.notificationListTimeBox} >
                                                <span className={SocialMediaCSS.notificationListTimeText} style={{ color: isHovering === notification?.notification_id ? theme.mainPalette.grey.white : theme.mainPalette.grey.lightText }}>{timeAgo(notification?.created_at)}</span>
                                            </Box>
                                        </Box>
                                    )
                                })}
                                {loading && <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                                    <Fade in={loading} style={{ transitionDelay: loading ? '100ms' : '0ms', }} unmountOnExit>
                                        <Box className={SocialMediaCSS.loadingBox}>
                                            <CircularProgress size={'sm'} />
                                        </Box>
                                    </Fade>
                                </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NotificationScreen